import './FYASiteCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import useFetch from '../../hooks/useFetch'
import { MESSAGES } from '../../utils/definitions'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const FYASiteCard = ({site}) => {
    const navigate = useNavigate()
    let query = `/reservations/filterReservations?reservationStatus=SUBMITTED&siteId=${site._id}`
    const { data, loading, error } = useFetch(query);

    useEffect(() => {
        if(error && error.message === MESSAGES.ERROR_401) {
        alert(MESSAGES.ERROR_SESSION_EXPIRED)
        localStorage.clear()
        window.location.pathname = '/login'
        }
    }, [error])

    const handleRedirect = () => {
        navigate('/reservationsFilter', { 
            state: {
                reservationStatus: 'SUBMITTED',
                siteId: site._id
            }
        })
    }

    return (
        <div onClick={()=> handleRedirect()} className="forYourActionLink">
            <div className="FYASite">
                <>
                    <FontAwesomeIcon 
                        icon={faBuilding}
                        className="FYAIcons"
                        />
                    <span className='FYASiteTitle'>{site.name}</span>
                </>
                <div className="FYAInfo">
                    {
                        (data.reservation === 0) ?
                            <span>None at the moment</span>
                        :
                                <span>{data.length} Reservation/s</span>
                    }
                </div>
            </div>
        </div>
    )
}

export default FYASiteCard