import './upcomingReservationsCard.css'

import { useContext } from 'react';
import { SitesContext } from '../../context/SitesContext';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import millisecondsToHours from 'date-fns/millisecondsToHours'
import millisecondsToMinutes from 'date-fns/millisecondsToMinutes'
import hoursToMilliseconds from 'date-fns/hoursToMilliseconds'

import { MESSAGES, SPACE_TYPES } from '../../utils/definitions.js';

const UpcomingReservationsCard = ({reservationInfo}) => {
    const { sites } = useContext(SitesContext);
    const { user } = useContext(AuthContext)
    const navigate = useNavigate();

    const sitesMatch = sites?.find( (item)=> (item._id === reservationInfo?.siteId))
    const siteName = sitesMatch?.name;
    const roomId = reservationInfo?.roomId;
    const reservation = reservationInfo;

    //time
    const startTime = new Date(reservationInfo.reservationStartTime)
    const timeNow = new Date()
    let diff = startTime.getTime() - timeNow.getTime()
    let hrsLeft = millisecondsToHours(diff)
    diff = diff - hoursToMilliseconds(hrsLeft)
    let minsLeft = millisecondsToMinutes(diff)

    let query = reservationInfo.facilityType === SPACE_TYPES.SEAT ? 
        `/seats/${roomId}?id=${user._id}`
        :
        `/rooms/${roomId}?id=${user._id}`

    const { data, loading, error } = useFetch(query);

    const handleViewDetails = (e) => {
        e.preventDefault();
        navigate(`/reservations/${reservationInfo._id}`, {
            state: { roomId, reservation },
        });
    };

    return (
        <div className="upComingReservationsItem">
            <h5>
                {siteName}
            </h5>
            <div className="upcomingReservationsItemBox">
                <div className="upcomingReservationsItemBoxDesc">
                    
                    <div className="upcomingReservationsItemRoomDetails">
                        {loading ? (
                            MESSAGES.LOADING
                            ) : error ? (
                                <>
                            <span>{error.message}</span>
                            <br />
                            <span>{MESSAGES.CONTACT}</span>
                            </>
                        ) : (
                            <>
                            <h4 className="upcomingReservationsItemBoxName">{data.name}</h4>
                            <span className="upcomingReservationsItemSubtitle">{data.description}</span>
                            </>
                        )}
                    </div>
                    <span className="upcomingReservationsItemDates">
                        <p> In 
                            { hrsLeft > 1 ? (
                                <>
                                    <span> {hrsLeft} hours</span>
                                </>
                                )
                            :
                             hrsLeft === 1 ? (
                                 <>
                                    <span> {hrsLeft} hour</span>
                                 </>
                             )
                             :
                                ''
                             } 
                             { minsLeft > 1 ? (
                                <>
                                    <span> {minsLeft} mins</span>
                                </>
                                )
                            :
                            minsLeft >= 0 && minsLeft < 2 ? (
                                 <>
                                    <span> {minsLeft} min</span>
                                 </>
                             )
                             :
                                <>
                                    <span>00 min</span>
                                </>
                             } 
                        </p>
                        
                        
                    </span>
                </div>
                <div className="upcomingReservationsItemDetails">
                    <button className="upcomingReservationsItemDetailsBtn" onClick={handleViewDetails}>
                        Check In 
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UpcomingReservationsCard
