import React from 'react'
import './formMessage.css'
import FormWrapper from '../formWrapper/FormWrapper'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleCheck,
  faCircleExclamation, faCircleInfo
} from "@fortawesome/free-solid-svg-icons"

const FormMessage = ({title, message, type}) => {
  return (
    <> 
      {
        (type === 'login' || type === 'reset') ?
          type === 'login' ?
            <div className='messageWrapper error'>
              <FontAwesomeIcon
                icon={ faCircleExclamation }
                className='errorIcon'
              />
              <p>{message}</p>
            </div>
          :
          <>
            <h2 className='multiFormTitle'>{title}</h2>
            <div className='messageWrapper error reset'>
              <FontAwesomeIcon
                icon={ faCircleExclamation }
                className='errorIcon'
              />
              <p>{message}</p>
            </div>
          </>
        :
        // type === info
        type === 'info' ?
          <>
            <h2 className='multiFormTitle'>{title}</h2>
            <div className='messageWrapper'>
              <FontAwesomeIcon
                icon={ faCircleInfo }
                className='infoIcon'
              />
              <p>{message}</p>
            </div>
          </>
        :
        // type === success
        <>
          <h2 className='multiFormTitle'>{title}</h2>
          <div className='messageWrapper'>
            <FontAwesomeIcon
              icon={ faCircleCheck }
              className='successIcon'
            />
            <p>{message}</p>
          </div>
        </>
      }
    </>
  )
}

export default FormMessage