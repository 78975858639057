import "./searchTypeItem.css"
import { classNameGeneratorActiveAndDisabled, searchTypeIcon } from "../../utils/helper.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SearchTypeItem = ({active, type, changeType, disabled}) => {
    const cssName = classNameGeneratorActiveAndDisabled('searchTypeItem', active, disabled)

    return (
        <div className={cssName} 
            onClick={ changeType }>
            <FontAwesomeIcon icon={ searchTypeIcon(type) } />
            <span>{type}</span>
        </div>
    )
}

export default SearchTypeItem
