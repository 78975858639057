import './upcomingReservations.css'

import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import useFetch from '../../hooks/useFetch.js'
import { useEffect } from 'react'

import AutoLogout from '../../components/autoLogout/AutoLogout'
import { MESSAGES, reservationStatus } from "../../utils/definitions.js"
import UpcomingReservationsCard from '../upcomingReservationsCard/UpcomingReservationsCard'

const UpcomingReservations = () => {
    const { user } = useContext(AuthContext)
    const nonAdminFetchQuery = `/reservations?domainId=${user.domainId}&id=${user._id}&reservationStatus=${reservationStatus.UPCOMING}`
    const { data, loading, error } = useFetch(nonAdminFetchQuery)

    useEffect(() => {
        if(error && error.message === MESSAGES.ERROR_401) {
        alert(MESSAGES.ERROR_SESSION_EXPIRED)
        localStorage.clear()
        window.location.pathname = '/login'
        }
    }, [error])

    return (
        <AutoLogout>
            <div>
                <h3>Upcoming Reservations</h3>
                <div>
                    {
                        loading ?
                            MESSAGES.LOADING
                        :
                            error ?
                                <>
                                    <span>{error.message}</span><br/>
                                    <span>{MESSAGES.ERROR_CONTACT_ADMIN}</span>
                                </>
                            :
                                (Object.keys(data).length === 0) ?
                                    "No Reservations"
                                :
                                <>
                                    {data.map( (item) => (
                                        <UpcomingReservationsCard key={item._id} reservationInfo={item}/>
                                    ))}
                                </> 
                    }
                </div>
            </div>
        </AutoLogout>
    )
}

export default UpcomingReservations
