import './titleImage.css'

const TitleImage = () => {
    return (
        <div className="titleImageContainer">
            CGS Reserve
        </div>
    )
}

export default TitleImage
