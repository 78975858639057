export const extractErrorMessage = (htmlResponse) => {
    let span = document.createElement('span')
    span.innerHTML = htmlResponse
    let message = span.textContent || span.innerText
    let startIndex = htmlResponse.indexOf(':')
    let endIndex = htmlResponse.indexOf('<br>')
    message = htmlResponse.substring(startIndex + 2, endIndex)
    return message
}

export const PASSWORD_VALIDATION = {
    LOWERCASE: 'One lowercase alphabet is required',
    UPPERCASE: 'One uppercase alphabet is required',
    NUMBER: 'One numeric digit is required',
    SPECIAL_CHAR: 'One special character is required',
    LENGTH: 'At least 8 characters is required',
    NOT_MATCHED: 'Passwords do not match',
    PASSED: 'Password passed'
}