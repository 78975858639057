import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import './resetPassword.css'
import carelonLogoSvg from "../../resources/carelon/carelon-logo-colored.svg"
import useMultiStepForm from '../../hooks/useMultiStepForm'
import DomainForm from '../../components/domainForm/DomainForm';
import FormMessage from '../../components/formMessage/FormMessage';

import { authUrl } from '../../utils/authRequest.js'
import { extractErrorMessage } from '../../utils/error.js'

const ResetPassword = () => {
    const navigate = useNavigate();
    const [inputDomainId, setInputDomainId] = useState('')
    const [domainErrorStatus, setDomainErrorStatus] = useState(false)
    const [domainErrorMessage, setDomainErrorMessage] = useState('')
    const [domainAuthResponse, setDomainAuthResponse] = useState('')
    const [isDomainValidated, setIsDomainValidated] = useState(false)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    
    const clearError = () => {
        setDomainErrorMessage('')
        setDomainErrorStatus(false)
    }
    const validateDomainId = (input) => {
        setInputDomainId(input)
        if (inputDomainId !== '') setIsDomainValidated(true)
        else setIsDomainValidated(false)
    }
    const handleNext = async e => {
        e.preventDefault();
        setDomainErrorStatus(false)
        setIsButtonLoading(true)
        setDomainErrorMessage('')
        //validate domain id
        if (currentStepIndex + 1 === 1) {
            try {
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: 'same-origin'
                }
                let obj = {
                    domainId: inputDomainId
                }
                const res = await authUrl.post("/resetRequest", obj, config)
                setDomainAuthResponse(res.data)
                setIsButtonLoading(false)
                next()
            } catch (error) {
                if (error.response?.data !== undefined) {
                    console.log(error.response?.data)
                    setDomainErrorStatus(true)
                    setDomainErrorMessage(extractErrorMessage(error.response?.data))
                    setIsButtonLoading(false)
                }
                    // dispatch({type:ACTION_TYPES.FAILURE, payload: error.response?.data})
                else {
                    setDomainErrorStatus(true)
                    setDomainErrorMessage(error?.message)
                    setIsButtonLoading(false)
                    console.log(error)
                }
                    // dispatch({type:ACTION_TYPES.FAILURE, payload: error})
            }
        }
    }
    const backAction = () => {
        clearError()
        setInputDomainId('')
        navigate('/')
    }
    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } = useMultiStepForm([
        <DomainForm setInputDomainId={setInputDomainId} handleNext={handleNext} clearError={clearError} title='Reset Your Password' validateDomainId={validateDomainId}/>,
        <FormMessage title='Reset Your Password' message='Please check your email inbox for a link to complete the reset.' type='info'/>
    ])
    return (
        <div className="login">
            <div className="upperSection">
                <div className="loginTitles">
                    <img src={carelonLogoSvg} alt="" className="loginCompanyLogo"/>
                </div>
            </div>
            <div className="lowerSection">
                <div className='multiStepContainer'>
                    {step}
                    {   domainErrorStatus && 
                        <div className='domainErrorMsg'>
                            {   Array.isArray(domainErrorMessage) ? 
                                    domainErrorMessage?.map( (error, i) => (
                                        <p className='passwordValidationError'>{error}</p>
                                    )) 
                                    : <>
                                        <p>{domainErrorMessage}</p>
                                    </>
                            }
                        </div>
                    }
                    <div className="multiStepButtons">
                        {
                            isLastStep ? ''
                            :
                            <>
                                <button type='button' onClick={backAction} >Cancel</button>
                                <button type='button' onClick={handleNext} disabled={(!isDomainValidated && !isButtonLoading) || (isDomainValidated && isButtonLoading)}>Reset Password</button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword