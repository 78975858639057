import './forYourAction.css'

import { useContext } from 'react'
import { SitesContext } from '../../context/SitesContext.js';
import FYASiteCard from '../FYASiteCard/FYASiteCard.jsx';

const ForYourAction = () => {
    const sites = useContext(SitesContext)

    return (
        <div className="forYourActions">
            <h3>For Approval</h3>
            <div className="FYASitesWrapper">
                { sites.map( (site, i) => (
                    <FYASiteCard key={i} site={site} />
                ))}
                {/* {
                    loading ?
                    MESSAGES.LOADING
                    :
                    error ?
                    <>
                                <span>{error.message}</span><br/>
                                <span>{MESSAGES.ERROR_CONTACT_ADMIN}</span>
                            </>
                        :
                        <>
                            </>
                } */}
            </div>
        </div>
    )
}

export default ForYourAction
