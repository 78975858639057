import { isNextHour, isPastHour } from '../../utils/helper'
import './selectTimeSearchBar.css'

const SelectTimeSearchBar = ({setTime, hours, nowUTC_toPHT, pickedDateStringL, }) => {
  return (
    <select 
        className="searchBarSelect" 
        id="selectFromTime" 
        onLoadedData={(e) => setTime(e.target.value)} 
        onChange={(e) => setTime(e.target.value) }
    >
        {
            hours ? 
                hours.map((item, i) => (
                    <option className="searchBarOptions" key={i} value={i} selected={ isNextHour(nowUTC_toPHT, pickedDateStringL, i)} disabled={isPastHour(nowUTC_toPHT, pickedDateStringL, i)} >{item}</option>
                ))
            : 
            ''
        }
    </select>
  )
}

export default SelectTimeSearchBar