import { useState, useRef } from 'react'
import './credentialsForm.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEyeSlash,
    faEye
  } from "@fortawesome/free-solid-svg-icons";
import FormWrapper from '../formWrapper/FormWrapper'
import TextField from '@mui/material/TextField';
import { passwordValidator } from '../../utils/helper';

const CredentialsForm = ({domainId, setFormActionText, validateCredentials, setCredentials, setFormErrorStatus, setFormErrorMessage, clearFormError, setFormError, handleNext}) => {
  return (
    <SetCredentials 
        setFormActionText={setFormActionText}
        validateCredentials={validateCredentials}
        setCredentials={setCredentials}
        setFormErrorStatus={setFormErrorStatus}
        setFormErrorMessage={setFormErrorMessage}
        clearFormError={clearFormError}
        setFormError={setFormError}
        handleNext={handleNext}
    />
  )
}

const SetCredentials = ({setFormActionText, validateCredentials, setCredentials, setFormErrorStatus, setFormErrorMessage, clearFormError, setFormError, handleNext}) => {
    setFormActionText('Set Password')
    const passwordInput = useRef()
    const confirmPasswordInput = useRef()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [ isPasswordHidden, setIsPasswordHidden ] = useState(true)

    const style = {
      "fieldset": {
        borderColor: "#5009B5"
      },
      "& label.Mui-focused": {
        color: "#5009B5"
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#5009B5"
        }
      }
    } 

    const togglePassword = e => {
      e.preventDefault();
      passwordInput.current.type = isPasswordHidden ? 'text' : 'password'
      confirmPasswordInput.current.type = isPasswordHidden ? 'text' : 'password'
      setIsPasswordHidden(!isPasswordHidden)
    }
    
    const handleChange = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleNext(e)
      }
      let input = e.target.value
      let id = e.target.id
      if(id === 'password') {
        setPassword(input)
        let isPasswordValid = passwordValidator(input)
        if(!isPasswordValid.includes('Password passed')) {
          setFormError(passwordValidator(input, confirmPassword), true)
        }
        else {
          clearFormError()
        }
      }
      if(e.target.id === 'confirmPassword') {
        console.log(password)
        setConfirmPassword(input)
        if(password !== input) setFormError(['Passwords do not match'], true)
        else clearFormError()
      }
      validateCredentials(input, id)
    }
    return(
      <FormWrapper title='Set Your New Password'>
        <div className="loginPasswordWrap">
          <TextField style={{width: 300}} sx={style} fullWidth id="password" className='multiForm-password' label="New Password" variant="outlined" onPaste={e => e.preventDefault()} onChange={handleChange} onKeyDown={handleChange} onKeyUp={handleChange} type='password' inputRef={passwordInput} />
          <FontAwesomeIcon icon={ isPasswordHidden === true ? faEyeSlash : faEye} className="loginEye" onClick={togglePassword}/>
        </div>
        <div className="loginPasswordWrap">
          <TextField style={{width: 300}} sx={style} fullWidth id="confirmPassword" className='multiForm-confirmPassword' label="Confirm New Password" variant="outlined" onPaste={e => e.preventDefault()} onChange={handleChange} onKeyDown={handleChange} onKeyUp={handleChange} type='password' inputRef={confirmPasswordInput}/>
          <FontAwesomeIcon icon={ isPasswordHidden === true ? faEyeSlash : faEye} className="loginEye" onClick={togglePassword}/>
        </div>
        
      </FormWrapper>
      )
}

export default CredentialsForm