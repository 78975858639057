import React, { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { format } from "date-fns";
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { SitesContext } from '../../context/SitesContext.js'
import { getSiteName } from '../../utils/helper';
import { MESSAGES } from '../../utils/definitions.js';

const ReservationRoomInfo = ({reservationInfo}) => {
    const { user } = useContext(AuthContext)
    const sitesCtx = useContext(SitesContext)
    let siteId
    const [siteName, setSiteName] = useState('')
    let query = reservationInfo.facilityType === 'seat' ? 
        `/seats/${reservationInfo.roomId}?domainId=${user.domainId}&id=${user._id}`
        :
        `/rooms/${reservationInfo.roomId}?domainId=${user.domainId}&id=${user._id}`
    const {data, loading, error} = useFetch(query)
    useEffect(() => {
      siteId = data?.siteId
      setSiteName(getSiteName(siteId, sitesCtx))
    }, [data])
    let createdTime_PHT = new Date(reservationInfo.createdAt).toLocaleString("en-US", {timeZone: "Asia/Manila"});
    return (
        <>
            <div className="reservationItemItem">
                <label>Request submitted on</label>
                <span>{`${format(new Date(createdTime_PHT), "dd MMM yyyy")}`}</span>
             </div>
             <div className="reservationItemItem">
                <label>Site Location</label>
                <span>{data ? siteName : MESSAGES.NO_ROOM_INFO}</span>
            </div>
            <div className="reservationItemItem">
                <label>Name of room</label>
                <span>{data ? data.name : MESSAGES.NO_ROOM_INFO}</span>
            </div>
            <div className="reservationItemItem">
                <label>Floor</label>
                <span>{data ? data.floor : MESSAGES.NO_ROOM_INFO}</span>
            </div>
            <div className="reservationItemItem">
                <label>Capacity</label>
                <span>{data ? data.capacity : MESSAGES.NO_ROOM_INFO}</span>
            </div>  
        </>
    )
}

export default ReservationRoomInfo
