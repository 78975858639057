import axios from "axios"
import { serverMode } from "./config";

const localhost = 'http://localhost:4004/auth';
const production = 'https://workplace.teamcgs.io/auth'

const baseUrlProduction = 'https://workplace.teamcgs.io'
const baseUrlLocalhost = 'http://localhost:3000'

export const authBaseUrl = serverMode ? baseUrlProduction : baseUrlLocalhost 

export const authUrl = axios.create({
    baseURL: (serverMode ? production : localhost),
    headers: {
        'Content-Type': 'application/json',
    },
})

