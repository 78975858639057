import { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import './resetPasswordRedirect.css'
import carelonLogoSvg from "../../resources/carelon/carelon-logo-colored.svg"
import useMultiStepForm from '../../hooks/useMultiStepForm'
import DomainForm from '../../components/domainForm/DomainForm';
import FormMessage from '../../components/formMessage/FormMessage';

import { authUrl } from '../../utils/authRequest.js'
import { extractErrorMessage } from '../../utils/error.js'
import { passwordRegex } from '../../utils/helper.js'
import PasswordForm from '../../components/passwordForm/PasswordForm.jsx'
import CredentialsForm from '../../components/credentialsForm/CredentialsForm.jsx'

const ResetPasswordRedirect = () => {
    const navigate = useNavigate()
    const { domainId, resetString } = useParams()

    useEffect( () => {
        const onPageLoad = async() => {
            if ((domainId !== '' || domainId !== null) && (resetString !== '' || resetString !== null)) {
                try {
                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: 'same-origin'
                    }
                    let objCreds = {
                        domainId: domainId,
                        resetString: resetString
                    }
                    const res = await authUrl.post("/isResetLinkValid", objCreds, config)
                    // console.log(res.data)
                    // res.data.permissions = permissionsAdapter(res.data.permissions)
                    // dispatch({ type:ACTION_TYPES.SUCCESS, payload: res.data })
                    // navigate('/')
                } catch (error) {
                    if (error.response?.data !== undefined) {
                        console.log(error.response?.data)
                        setResetDataErrorStatus(true)
                        setResetDataErrorMessage(extractErrorMessage(error.response?.data))
                    }
                    else 
                        console.log(error)
                }
            }
        };
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, [])
    const [resetDataErrorStatus, setResetDataErrorStatus] = useState(false)
    const [resetDataErrorMessage, setResetDataErrorMessage] = useState(false)
    const [formErrorStatus, setFormErrorStatus] = useState(false)
    const [formErrorMessage, setFormErrorMessage] = useState('')
    const [formActionText, setFormActionText] = useState('')
    const [formAction, setFormAction] = useState(0)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [credentials, setCredentials] = useState({
        domainId: domainId,
        password: '',
        confirmPassword: ''
    })
    const [isFormValidated, setIsFormValidated] = useState(false)

    const clearFormError = () => {
        setFormErrorMessage('')
        setFormErrorStatus(false)
    }

    const setFormError = (errorMsg, errorStatus) => {
        setFormErrorMessage(errorMsg)
        setFormErrorStatus(errorStatus)
    }

    const validateCredentials = (input, id) => {
        setCredentials(prev => ({...prev, [id]: input }))
        if(formActionText === 'Set Password') {
            if((credentials.password !== ''
            && credentials.confirmPassword !== '') 
            && passwordRegex.test(credentials.password)
            &&
            (
                credentials.password === credentials.confirmPassword
            )
            ) {
                setIsFormValidated(true)
            }
            else {
                setIsFormValidated(false)
            }
        }
    }

    const handleLogin = () => {
        navigate('/')
    }

    const handleNext = async e => {
        e.preventDefault();
        setIsButtonLoading(true)
        setFormErrorStatus(false)
        setFormErrorMessage('')
        
        //set new credentials
        if (currentStepIndex + 1 === 1) {
            if(formActionText === 'Set Password') {
                try {
                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: 'same-origin'
                    }
                    let objCreds = {
                        domainId: domainId,
                        password: credentials.password
                    }
                    const res = await authUrl.post("/setNewPassword", objCreds, config)
                    setIsButtonLoading(false)
                    next()
                } catch (error) {
                    if (error.response?.data !== undefined) {
                        console.log(error.response?.data)
                        setFormErrorStatus(true)
                        setIsButtonLoading(false)
                        setFormErrorMessage(extractErrorMessage(error.response?.data))
                    }
                    else 
                        console.log(error)
                }
            }
        }
    }

    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } = useMultiStepForm([
        <CredentialsForm domainId={domainId} setFormActionText={setFormActionText} validateCredentials={validateCredentials} setFormError={setFormError} clearFormError={clearFormError}/>,
        <FormMessage title='Password Reset Success' message='You have successfully completed the password reset process.' type='success'/>
    ])

    return (
        <div className="login">
            <div className="upperSection">
                <div className="loginTitles">
                    <img src={carelonLogoSvg} alt="" className="loginCompanyLogo"/>
                </div>
            </div>
            <div className="lowerSection">
                <div className='multiStepContainer'>
                    <form>
                        <div className="stepCounter">{currentStepIndex + 1} / {steps.length}</div>
                        { resetDataErrorStatus ? 
                            <>
                                <FormMessage title='Password Reset' message={resetDataErrorMessage} type='reset'/>
                            </> 
                            : 
                            <>
                                {step}
                            </>
                        }
                        {
                            formErrorStatus && 
                                <div className='domainErrorMsg'>
                                    {   
                                        Array.isArray(formErrorMessage) ? 
                                            formErrorMessage?.map( (error, i) => (
                                                <p className='passwordValidationError'>{error}</p>
                                            )) 
                                            : 
                                            ''
                                    }
                                </div>
                        }
                        
                        <div className="multiStepButtons">
                            {
                                isLastStep ? 
                                <>
                                    <button type='button' onClick={handleLogin}>Login</button>
                                </>
                                :
                                resetDataErrorStatus ? 
                                ''
                                :
                                <>
                                    <button type='button' >Cancel</button>
                                    <button type='button' onClick={handleNext} disabled={(!isFormValidated && !isButtonLoading) || (isFormValidated && isButtonLoading)} >{formActionText}</button>
                                </>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>

        
    )
}

export default ResetPasswordRedirect