import React from 'react'
import './formWrapper.css'

const FormWrapper = ({title, children}) => {
  return (
    <>
        <h2 className='multiFormTitle'>{title}</h2>
        <div className='multiFormElements'>
            {children}
        </div>
    </>
  )
}

export default FormWrapper