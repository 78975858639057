import axios from "axios"
import { serverMode } from "./config";

const localhost = 'http://localhost:8080/api';
const production = 'https://workplace.teamcgs.io/api'

const requestUrl = axios.create({
    baseURL: (serverMode ? production : localhost),
    headers: {
        'Content-Type': 'application/json',
    },
})

export default requestUrl