import './home.css'
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { format } from "date-fns";
import TitleImage from '../../../components/titleImage/TitleImage.jsx'
import AutoLogout from '../../../components/autoLogout/AutoLogout';
import ForYourAction from '../../../components/forYourAction/ForYourAction';

const Home = () => {
    const { user } = useContext(AuthContext)
    const dateTimeNow = new Date()
    const dateTimeNow_PHT = new Date(dateTimeNow).toLocaleString("en-US", {timeZone: "Asia/Manila"})
    const [displayTime, setDisplayTime] = useState(dateTimeNow_PHT)
    setInterval(() => {
        setDisplayTime(new Date().toLocaleString("en-US", {timeZone: "Asia/Manila"}))
    }, 1000);
    return (
        <AutoLogout>
            <div className="adminHomeContainer">
                <TitleImage />
                <div className="adminHomeBody">
                    <div className="homeTopSection">
                        <div className="homeTopSectionLowerRow">
                            <div className="homeGreetings">
                                Good day, { user ? `${user.firstName}!` : ''}
                            </div>
                            <div className="homeTimeNow">
                                It's { format(new Date(displayTime), "MMMM dd, yyyy hh:mm:ss bb") }
                            </div>
                        </div>
                        <span>Admin Dashboard</span>
                    </div>
                    <div className="homeLowerSection">
                        <div className="tiles awaiting">
                            <ForYourAction />
                        </div>
                        {/* <div className="tiles tasks">
                            <h3>Quick Tasks</h3>
                            <Link to='/reports'>
                                <button className="homeBtn">Reports</button>
                            </Link>
                            <Link to='/search'>
                                <button className="homeBtn">Reserve</button>
                            </Link>
                        </div> */}
                        {/* <div className="tiles upcoming">
                            <h3>Upcoming Booking</h3>
                            <div>No upcoming booking.</div>
                        </div> */}
                    </div>
                    
                    
                </div>
            </div>
        </AutoLogout>
    )
}

export default Home
