import { useLocation } from 'react-router-dom'
import './reservationsFilter.css'
import { convertFiltersToQueryParams } from '../../utils/helper'
import { useEffect, useState } from 'react'
import requestUrl from '../../utils/requestMethods'
import ReservationsCard from '../reservationsCard/ReservationsCard'

const ReservationsFilter = () => {
    const location = useLocation()
    console.log('location.state', location.state)
    let stringQueryParams = convertFiltersToQueryParams(location.state)
    console.log('stringQueryParams', stringQueryParams)
    const [ filteredData, setFilteredData] = useState([])

    const fetchFilteredReservations = async () => {
        try {
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: "same-origin",
            }
            const { data } = await requestUrl.get(`/reservations/filterReservations?${stringQueryParams}`, config)
            console.log(data)
            setFilteredData(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchFilteredReservations()
    }, [location.state])
    
    return (
        <div className='reservationsContainer'>
            <div className="reservationsHeader">
                <h3>View Reservations</h3> <span>Reservation Status: {location.state.reservationStatus}</span> <span>Site: {location.state.siteId}</span>
            </div>
            <div className="reservationsContent">
                <div className="reservationsContentWrapper">
                    <div className="reservationsContentList">
                        {
                            (Object.keys(filteredData).length === 0) ?
                                "No Reservations"
                            :
                                <>
                                    {filteredData.map( (item) => (
                                        <ReservationsCard key={item._id} reservationInfo={item}/>
                                    ))}
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReservationsFilter